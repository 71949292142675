<template>
  <div class="login-form">
    <h2 class="text-xl font-bold mb-4">
      {{ authStore.isLoggedIn ? "" : "Customer Login" }}
    </h2>
    <form v-if="!authStore.isLoggedIn" @submit.prevent="login">
      <div class="mb-4">
        <label for="username" class="block mb-2"
          >E-mail Address / Bidder ID:</label
        >
        <input
          type="text"
          id="username"
          v-model="username"
          required
          class="w-full p-2 border rounded"
          autocomplete="username"
        />
      </div>
      <div class="mb-4">
        <label for="password" class="block mb-2">Password:</label>
        <input
          type="password"
          id="password"
          v-model="password"
          required
          class="w-full p-2 border rounded"
          autocomplete="current-password"
        />
      </div>
      <button
        type="submit"
        class="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        :disabled="isLoading"
      >
        {{ isLoading ? "Logging in..." : "Login" }}
      </button>
    </form>
    <div v-else>
      <p>You are logged in as {{ authStore.username }}.</p>
      <button
        @click="logout"
        class="w-full mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
      >
        Logout
      </button>
    </div>
    <p v-if="errorMessage" class="mt-4 text-red-500">{{ errorMessage }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useAuthStore } from "~/stores/auth";
import type { UserData, UserProfile } from "~/stores/auth";

/**
 * {
    "user": {
        "profile": {
            "id": 806423,
            "firstName": "Brian",
            "lastName": "Clifton",
            "email": "briansclifton75@gmail.com",
            "dateofBirth": "1975-04-16",
            "company": "",
            "unit": "",
            "address": "6549 hollow",
            "city": "Loveland",
            "state": "Ohio",
            "zip": "45140",
            "phone1": "5132006801",
            "phone2": "5132006801",
            "smsNumber": null,
            "offer": false,
            "payment": false,
            "savedSearch": false,
            "smsNotification": 1,
            "smsVerificationStatus": false,
            "emailNotification": false,
            "emailVerificationStatus": true,
            "customerId": "cus_PmhxDSAtbVa263",
            "isAddedCard": true,
            "bcCustomerId": 0,
            "newBidder": true,
            "marketingOptedIn": true,
            "preferredZipcode": "",
            "favoriteLocations": null
        },
        "dashboardCounts": {
            "receipts": 194,
            "archivedReceipts": 0,
            "notifications": 402,
            "watchlist": 0,
            "bids": 0,
            "invoices": 0,
            "cards": 4,
            "winningWatchList": 0,
            "winningBids": 0,
            "winningBidTotal": 0
        },
        "watchlistItemIds": [],
        "isSubscribed": false
    },
    "expires": "2024-08-06T01:55:05.397Z",
    "accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4MDY0MjMiLCJpYXQiOjE3MjAzMTczMDUsImV4cCI6MTcyMDQwMzcwNX0.ymBkzrL3WDAsmxmkIV6UiWBfs9GXXDu5WMuKpJCvj9fYziGN0GeBxML2libZjRDlL2hE6sSgbvYK5GfwD5_-tQ"
}

export interface UserProfile {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dateofBirth: string;
  company: string;
  unit: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone1: string;
  phone2: string;
  smsNumber: string | null;
  offer: boolean;
  payment: boolean;
  savedSearch: boolean;
  smsNotification: number;
  smsVerificationStatus: boolean;
  emailNotification: boolean;
  emailVerificationStatus: boolean;
  customerId: string;
  isAddedCard: boolean;
  bcCustomerId: number;
  newBidder: boolean;
  marketingOptedIn: boolean;
  preferredZipcode: string;
  favoriteLocations: any;
}

export interface DashboardCounts {
  receipts: number;
  archivedReceipts: number;
  notifications: number;
  watchlist: number;
  bids: number;
  invoices: number;
  cards: number;
  winningWatchList: number;
  winningBids: number;
  winningBidTotal: number;
}

export interface User {
  profile: UserProfile;
  dashboardCounts: DashboardCounts;
  watchlistItemIds: number[];
  isSubscribed: boolean;
}

export interface UserData {
  user: User;
  expires: string;
  accessToken: string;
}
 */

const authStore = useAuthStore();

const username = ref("");
const password = ref("");
const isLoading = ref(false);
const errorMessage = ref("");

onMounted(() => {
  authStore.checkSession();
});

async function login() {
  isLoading.value = true;
  errorMessage.value = "";

  try {
    const loginResponse = await ($fetch as any)("/api/auth/login", {
      method: "POST",
      body: {
        username: username.value,
        password: password.value,
      },
    });

    const userData: UserData | null = loginResponse?.sessionData;

    console.log("Login response:", loginResponse, userData);

    if (!userData) {
      console.warn("No user data received from the server");
    } else {
      console.log("Login successful:", userData);

      const userEmail = userData.user.profile.email;
      authStore.login(userEmail, userData);
    }
  } catch (error) {
    console.error("Error during login:", error);
    errorMessage.value = "An error occurred. Please try again later.";
  } finally {
    isLoading.value = false;
  }
}

async function logout() {
  await authStore.logout();
  username.value = "";
  password.value = "";
}
</script>
